export const SET_DELIVERY = 'SET_DELIVERY';
export const GET_DELIVERY_REQUEST = 'GET_DELIVERY_REQUEST';

export const getDeliveryRequest = (suburb, postcode, weight, pickupDate, onComplete) => ({
  type: GET_DELIVERY_REQUEST,
  suburb,
  postcode,
  weight,
  pickupDate,
  onComplete
});
