import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { CartContext } from '../context/CartContext';

import { useMobile } from '../hooks/useMobile';

import { getDeliveryRequest } from '../api/delivery/delivery.action';
import { getExistingOrdersRequest } from '../api/order/order.action';
import { convertToAddressString } from '../utils/currency/tools';
import { getFromLocalStorage, saveToLocalStorage, STORAGE_KEYS } from '../utils/storage/storage';

export const CartProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [cart, setCart] = useState();
  const [cartOpen, setCartOpen] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const order = useSelector(({ orders }) => orders.order);
  const serviceFeePercentage = 0.05;
  const { delivery: deliveryFee, loading: deliveryLoading } = useSelector(
    ({ delivery }) => delivery
  );

  const { existingOrders } = useSelector((state) => state.orders);

  const [address, setAddress] = useState(
    convertToAddressString(getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS), true)
  );

  const location = useMemo(() => {
    const storedAddress = getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS);
    return storedAddress?.location;
    //eslint-disable-next-line
  }, [address]);

  const memoizedCart = useMemo(
    () => ({
      items: cart?.items ?? [],
      id: cart?.id ?? uuidv4()
    }),
    [cart]
  );

  useEffect(() => {
    const storedCart = getFromLocalStorage(STORAGE_KEYS.CART);
    setCart(storedCart);
  }, []);

  const memoizedCartCount = useMemo(() => {
    return memoizedCart.items.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  }, [memoizedCart]);

  const handleSetCart = (cart) => {
    setCart(cart);
    saveToLocalStorage(STORAGE_KEYS.CART, cart);
  };

  const handleAddToCart = (item) => {
    handleSetCart({
      ...memoizedCart,
      items: [
        ...memoizedCart.items,
        {
          ...item,
          quantity: item.quantity ?? 1
        }
      ]
    });
  };

  const handleRemoveFromCart = (item) => {
    handleSetCart({
      items: memoizedCart.items.filter((cartItem) => cartItem.id !== item.id)
    });
  };

  const handleIncrementItem = (item) => {
    handleSetCart({
      items: memoizedCart.items.map((cartItem) =>
        cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
      )
    });
  };

  const handleDecrementItem = (item) => {
    handleSetCart({
      items: memoizedCart.items
        .map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
        )
        .filter((cartItem) => cartItem.quantity > 0)
    });
  };

  const memoizedCartSubTotal = useMemo(() => {
    return memoizedCart.items.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  }, [memoizedCart]);

  const handleIsItemInCart = (item) => {
    return memoizedCart.items.find((cartItem) => cartItem.id === item.id);
  };

  const serviceFee = useMemo(
    () => memoizedCartSubTotal * serviceFeePercentage,
    [memoizedCartSubTotal]
  );

  const memoizedCartDiscount = useMemo(() => {
    return (
      memoizedCartSubTotal * (existingOrders.filter((item) => item?.paid).length === 0 ? 0.1 : 0)
    );
    //eslint-disable-next-line
  }, [memoizedCartSubTotal, existingOrders]);

  const memoizedCartTotal = useMemo(() => {
    return (
      memoizedCartSubTotal * (1 + serviceFeePercentage) +
      (address && memoizedCartCount > 0 ? deliveryFee : 0) -
      memoizedCartDiscount
    );
    //eslint-disable-next-line
  }, [memoizedCartSubTotal, address, memoizedCartDiscount, deliveryFee, deliveryLoading]);

  const getTomorrowsDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const day = String(tomorrow.getDate()).padStart(2, '0');
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = tomorrow.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const debounceTimeout = useRef(null);
  useEffect(() => {
    if (cartOpen || isMobile) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const address = getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS);
        if (address && memoizedCart.items && memoizedCart.items.length > 0) {
          const weight = Math.ceil(memoizedCart.items.length * 0.3);
          dispatch(
            getDeliveryRequest(address.suburb, address.postalCode, weight, getTomorrowsDate())
          );
        }
      }, 300);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
    //eslint-disable-next-line
  }, [address, memoizedCart, cartOpen]);

  useEffect(() => {
    dispatch(getExistingOrdersRequest());
    //eslint-disable-next-line
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart: memoizedCart,
        setCart: handleSetCart,
        cartOpen,
        setCartOpen,
        cartCount: memoizedCartCount,
        addToCart: handleAddToCart,
        incrementItem: handleIncrementItem,
        decrementItem: handleDecrementItem,
        removeFromCart: handleRemoveFromCart,
        isItemInCart: handleIsItemInCart,
        cartTotal: memoizedCartTotal,
        address,
        setAddress,
        location,
        showOrderModal,
        setShowOrderModal,
        order,
        serviceFee,
        deliveryFee,
        deliveryLoading,
        discount: memoizedCartDiscount,
        subtotal: memoizedCartSubTotal
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
