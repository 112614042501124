import {
  CREATE_ORDER_REQUEST,
  GET_EXISTING_ORDER_REQUEST,
  SET_EXISTING_ORDERS,
  SET_ORDER
} from './order.action';

const initialState = {
  order: null,
  existingOrders: [],
  loading: false,
  error: undefined
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SET_ORDER:
      return {
        ...state,
        loading: false,
        order: action.data || [],
        error: action.error || undefined
      };
    case GET_EXISTING_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SET_EXISTING_ORDERS:
      return {
        ...state,
        loading: false,
        existingOrders: action.data || [],
        error: action.error || undefined
      };
    default:
      return state;
  }
};

export default orderReducer;
