import axios from 'axios';
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  CREATE_ORDER_REQUEST,
  GET_EXISTING_ORDER_REQUEST,
  SET_EXISTING_ORDERS,
  SET_ORDER
} from './order.action';

import { createGetRequest, createPostRequest } from '../tools/tools';

function* createOrder({ order, user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createPostRequest(user, '/order', order);

    const response = yield axios(endpoint, requestOptions);

    yield put({ type: SET_ORDER, data: response.data, error: undefined });
    if (onComplete) yield onComplete(response.data, null);
  } catch (error) {
    yield put({ type: SET_ORDER, data: undefined, error });
    if (onComplete) yield onComplete(null, error);
  }
}

function* watchCreateOrder() {
  yield takeLatest(CREATE_ORDER_REQUEST, createOrder);
}

function* existingOrder({ user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createGetRequest(user, `/order/user/${user.uid}`);

    const response = yield axios(endpoint, requestOptions);

    yield put({ type: SET_EXISTING_ORDERS, data: response.data, error: undefined });
    if (onComplete) yield onComplete(response.data, null);
  } catch (error) {
    yield put({ type: SET_EXISTING_ORDERS, data: undefined, error });
    if (onComplete) yield onComplete(null, error);
  }
}

function* watchGetExistingOrder() {
  yield takeLatest(GET_EXISTING_ORDER_REQUEST, existingOrder);
}

export default function* watchOrders() {
  yield all([watchCreateOrder(), watchGetExistingOrder()]);
}
