import { all } from 'redux-saga/effects';

import watchCategories from './category/category.saga';
import watchCustomFields from './custom-field/custom-field.saga';
import watchDelivery from './delivery/delivery.saga';
import watchMenus from './menu/menu.saga';
import watchOrders from './order/order.saga';
import watchPromotions from './promotion/promotion.saga';

export default function* rootSaga() {
  yield all([
    watchMenus(),
    watchCategories(),
    watchPromotions(),
    watchCustomFields(),
    watchOrders(),
    watchDelivery()
  ]);
}
