import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCart } from '../../../hooks/useCart';
import { useMobile } from '../../../hooks/useMobile';

import { ROUTE_PATH } from '../../../route/utils';

import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg';
import { ReactComponent as LocationIcon } from '../../../icons/location.svg';

import './cart.scss';
import { CartItem } from './item/CartItem';

import { Currency } from '../../../utils/currency/Currency';
import Button from '../../common/button/Button';
import { EmptyState } from '../../empty-state/EmptyState';
import { AddressSelectModal } from '../../modal/address-select/AddressSelectModal';

const Cart = () => {
  const {
    cart,
    cartTotal,
    setCartOpen,
    setCart,
    setShowOrderModal,
    address,
    serviceFee,
    deliveryFee,
    deliveryLoading,
    discount
  } = useCart();
  const isMobile = useMobile();
  const navigate = useNavigate();
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  const handleClearCart = () => {
    setCart({});
    setCartOpen(false);
  };

  const handleCheckout = () => {
    setShowOrderModal(true);
    navigate(ROUTE_PATH.MENU, { replace: true });
  };

  return (
    <div className="cart">
      <div className="cart__header">
        <div>
          <ArrowLeftIcon width={24} height={24} onClick={() => setCartOpen(false)} />
          {cart.items.length > 0 && <span onClick={handleClearCart}>Clear</span>}
        </div>
        <span>Cart</span>
        {cart.items.length > 0 && <span onClick={handleClearCart}>Clear</span>}
      </div>
      {cart.items.length > 0 && (
        <>
          <CartDelivery onClick={() => setDeliveryModalOpen(true)} />
          <div className="cart__items">
            <span>Items</span>
            <div>
              {cart.items.map((item) => (
                <CartItem key={`Cart Item ${item.id}`} item={item} />
              ))}
            </div>
          </div>

          <div className="cart__footer">
            <div className="cart__fees">
              {discount > 0 && (
                <div>
                  <span>Discount</span>
                  <Currency value={discount} />
                </div>
              )}
              {address && (
                <div>
                  <span>Delivery</span>
                  {!deliveryLoading && <Currency value={deliveryFee} />}
                  {deliveryLoading && <span>Calculating...</span>}
                </div>
              )}
              <div>
                <span>Service Fee</span>
                <Currency value={serviceFee} />
              </div>
              <div>
                <span>Total Amount</span>
                <Currency value={cartTotal} />
              </div>
            </div>
            <Button onClick={handleCheckout} disabled={!address}>
              CHECKOUT • <Currency value={cartTotal} />
            </Button>
          </div>
        </>
      )}
      {cart.items.length === 0 && (
        <div className="cart__empty">
          <EmptyState
            icon={() => <img src="/images/empty-cart.png" alt="empty cart" />}
            title="Your Cart is Empty!"
            description="Looks like you haven't added anything to your cart yet.
                             Browse our menu and fill it with your favorite items!"
            action={isMobile ? 'SEE MENU' : null}
            onActionClick={() => navigate(ROUTE_PATH.MENU, { replace: true })}
          />
        </div>
      )}
      <AddressSelectModal open={deliveryModalOpen} onOpen={setDeliveryModalOpen} />
    </div>
  );
};

const CartDelivery = ({ onClick }) => {
  const { address } = useCart();

  return (
    <div className="cart__delivery" onClick={onClick}>
      <div>
        <LocationIcon width={24} height={24} />
      </div>
      <div>
        <span>{address ? 'Delivery' : 'None Selected'}</span>
        <span>{address ?? 'Please select a delivery address'}</span>
      </div>
    </div>
  );
};

export default Cart;
