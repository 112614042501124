import { GET_DELIVERY_REQUEST, SET_DELIVERY } from './delivery.action';

const initialState = {
  delivery: 0,
  loading: false,
  error: undefined
};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERY_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SET_DELIVERY:
      return {
        ...state,
        loading: false,
        delivery: action.data || 0,
        error: action.error || undefined
      };
    default:
      return state;
  }
};

export default deliveryReducer;
