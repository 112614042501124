import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import { GET_DELIVERY_REQUEST, SET_DELIVERY } from './delivery.action';

function* getDelivery({ suburb, postcode, weight, pickupDate, onComplete }) {
  try {
    const endpoint = `https://sa.api.fastway.org/v3/psc/eta/PRY/${
      suburb
    }/${postcode}/${weight}?pickupDate=${pickupDate}&api_key=${
      process.env.REACT_APP_FASTWAY_API_KEY ?? 'aff7314aa02243c524d7ea0a4499d54a'
    }`;

    const response = yield axios.get(endpoint);

    let delivery = 100;

    response.data?.result?.services?.forEach((service) => {
      if (service.type === 'Parcel') {
        delivery = Math.round(service.totalprice_normal_exgst * 1.15 * 100) / 100;
      }
    });

    yield put({ type: SET_DELIVERY, data: delivery, error: undefined });
  } catch (error) {
    yield put({ type: SET_DELIVERY, data: 100, error });
    if (onComplete) onComplete(error);
  }
}

export default function* watchDelivery() {
  yield takeLatest(GET_DELIVERY_REQUEST, getDelivery);
}
