import { combineReducers } from 'redux';

import categoryReducer from './category/category.reducer';
import customFieldReducer from './custom-field/custom-field.reducer';
import deliveryReducer from './delivery/delivery.reducer';
import menuReducer from './menu/menu.reducer';
import orderReducer from './order/order.reducer';
import promotionReducer from './promotion/promotion.reducer';

const rootReducer = combineReducers({
  menu: menuReducer,
  categories: categoryReducer,
  promotions: promotionReducer,
  customFields: customFieldReducer,
  orders: orderReducer,
  delivery: deliveryReducer
});

export default rootReducer;
