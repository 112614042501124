import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAuth } from '../../../hooks/useAuth';
import { useCart } from '../../../hooks/useCart';

import './order.scss';

import { createOrderRequest } from '../../../api/order/order.action';
import { Currency } from '../../../utils/currency/Currency';
import { formatCurrency } from '../../../utils/currency/tools';
import Button from '../../common/button/Button';

const postFormData = (url, params) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;

  Object.keys(params).forEach((key) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = btoa(params[key]);
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const Order = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const {
    cart,
    cartTotal,
    setCart,
    setShowOrderModal,
    address,
    serviceFee,
    deliveryFee,
    location,
    subtotal,
    discount
  } = useCart();
  const [cartData, setCartData] = useState({
    cart,
    cartTotal,
    serviceFee
  });
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setCart(cartData.cart);
    setShowOrderModal(false);
  };

  const handlePostFormData = async (order) => {
    const config = getRemoteConfig();

    config.settings = {
      minimumFetchIntervalMillis: 3600000
    };
    await fetchAndActivate(config);

    const environment = process.env.REACT_APP_NODE_ENV;
    const apiEndpoint = getValue(config, `${environment}_payment_api_endpoint`).asString();

    postFormData(apiEndpoint, {
      param_1: order.amount,
      param_2: formatCurrency(order.amount),
      param_3: order.orderNumber,
      param_4: 'false',
      param_5: order.email
    });
  };

  const handleOrder = () => {
    if (cart) {
      setLoading(true);
      let order = {
        address: address,
        amount: cartTotal,
        email: user?.email,
        location: location ? JSON.stringify(location) : null,
        orderData: JSON.stringify(cart),
        userId: user.uid,
        cartId: cart.id,
        delivery: deliveryFee,
        serviceFees: serviceFee,
        subtotal
      };

      if (discount) {
        order = { ...order, discount };
      }

      setCartData({ cart, cartTotal, serviceFee: serviceFee });
      setCart({});
      dispatch(
        createOrderRequest(order, async (order) => {
          if (order) {
            await handlePostFormData(order);
          } else {
            setCart(cartData.cart);
            setLoading(false);
          }
        })
      );
    }
  };

  return (
    <div className="order">
      <h1>Payment</h1>
      <div>
        {address && (
          <div>
            <span>Delivery:</span>
            <Currency value={deliveryFee} />
          </div>
        )}
        {cartData.serviceFee && (
          <div>
            <span>Service Fee:</span>
            <Currency value={cartData.serviceFee} />
          </div>
        )}
        <div>
          <span>Total:</span>
          <Currency value={cartData.cartTotal} />
        </div>
        <div>
          <span>Method:</span>
          <span>Payfast</span>
        </div>
      </div>
      <Button disabled={loading} onClick={handleOrder}>
        {loading ? 'PROCESSING...' : 'PAY NOW'}
      </Button>
      <Button secondary onClick={handleCancel}>
        CANCEL
      </Button>
    </div>
  );
};
