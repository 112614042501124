export const SET_ORDER = 'SET_ORDER';
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const SET_EXISTING_ORDERS = 'SET_EXISTING_ORDERS';
export const GET_EXISTING_ORDER_REQUEST = 'GET_EXISTING_ORDER_REQUEST';

export const createOrderRequest = (order, onComplete) => ({
  type: CREATE_ORDER_REQUEST,
  order,
  onComplete
});

export const getExistingOrdersRequest = (onComplete) => ({
  type: GET_EXISTING_ORDER_REQUEST,
  onComplete
});
